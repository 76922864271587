.header {
    @apply fixed top-0 z-50 flex h-20 w-full items-center bg-blue-800 transition-all md:h-40;
    transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1), border 0ms linear;
    &.rc {
        &:before {
            .menu-is-open & {
                @apply opacity-0;
            }
        }
    }
    &:after {
        @apply absolute inset-x-0 top-full z-0 h-[2px] content-[""];
        .scrolled & {
            @apply bg-green-500;
        }
    }
    .scrolled & {
        @apply shadow-below-header shadow-black/25;
        @screen md {
            @apply h-20;
        }
    }
    &__top {
        @apply relative hidden transition-all md:flex md:gap-3;
        .scrolled & {
            @screen md {
                @apply invisible origin-top scale-y-0 opacity-0;
            }
        }
    }
}

.logo {
    @apply inline-block w-28 transition-all md:w-36;
    .scrolled & {
        @apply md:w-28;
    }
}

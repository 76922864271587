@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Configuration and helpers
// @import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// Base stuff
@import "base/all";

// Layout-related sections
@import "layout/grid";
@import "layout/header";
@import "layout/footer";

// Components
@import "components/all";

// Elements
@import "elements/all";

// Page-specific styles
// @import 'pages/home';

// Themes
@import "themes/default";

// Vendors
@import "vendors/datepicker";

.pagination {
    @apply flex gap-4 text-base;
    &__li {
        @apply sr-only;
        @screen md {
            @apply not-sr-only;
        }
        &:first-child,
        &:last-child {
            @apply not-sr-only;
        }
        &:nth-child(n + 10) {
            @screen md {
                @apply sr-only;
            }
        }
        &--active {
            @apply not-sr-only;
            & + li {
                @apply not-sr-only;
            }
        }
    }
}

.nx-accordion {
    @apply relative mb-4;
    &__head {
        @apply z-0 flex gap-3 transition-colors;
        &--active {
            @apply text-green-600;
        }
        .nx-accordion--filters & {
            @screen lg {
                @apply pointer-events-none w-full border-b border-blue-800 py-2 font-medium text-inherit;
            }
        }
        &-text {
            @apply block pt-1 transition-colors;
            .nx-accordion__head:hover & {
                @apply text-green-600;
            }
        }
    }
    &__content {
        @apply relative z-10 hidden py-3 pl-[42px];
        .nx-accordion--flush & {
            @apply pl-0;
        }
        .nx-accordion--filters & {
            @screen lg {
                display: block !important;
            }
        }
    }
    &__icon {
        @apply fill-blue-800 transition-transform ease-in;
        [aria-expanded="true"] & {
            @apply rotate-90 fill-green-600;
        }
        .nx-accordion--filters & {
            @screen lg {
                @apply hidden;
            }
        }
    }
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//     @apply leading-tight;
// }

@layer base {
    h1 {
        @apply mb-6 font-roboto-serif text-4xl font-medium md:text-5xl;
    }

    h2 {
        @apply mb-5 text-3xl;

        .wysiwyg & {
            @apply text-4xl font-medium font-roboto-serif;
        }
    }

    h3 {
        @apply mb-4 text-2xl;

        .wysiwyg & {
            @apply font-medium font-roboto-serif;
        }
    }

    h4 {
        @apply mb-3 text-xl md:text-2xl;
    }

    h5 {
        @apply mb-2 text-lg md:text-xl;
    }

    p {
        @apply mb-6 leading-snug;

        &:last-child {
            @apply mb-0;
        }
    }
}

a {
    @apply text-inherit transition-all;
    .wysiwyg &:not(.btn) {
        @extend .text-link;
    }
}

.text-link {
    @apply text-green-600 underline decoration-2 transition-all;
    &:hover {
        @apply decoration-4;
    }
    &:active {
        @apply bg-green-200;
    }
}

small {
    @apply text-sm;
}

strong,
b {
    @apply font-bold;
}

em,
i {
    @apply italic;
}

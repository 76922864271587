@layer components {
    input {
        @apply w-full rounded-l-lg rounded-r-3xl border border-green-500 bg-green-200 px-4 py-3 text-blue-800 transition-all placeholder:opacity-100;
        @screen md {
            @apply px-5 py-4;
        }
        &:hover {
            @apply border-blue-800;
        }
        &[type="file"] {
            @apply rounded-none border-2 border-dotted border-green-600 bg-transparent p-8;
            &::file-selector-button {
                @apply mr-3 cursor-pointer rounded-full bg-white px-6 py-4 font-medium text-green-600 shadow-none transition-all;
                border: 2px solid theme("colors.green.600");
                &:hover {
                    @apply shadow-below shadow-green-600/75;
                }
                &:active {
                    @apply border-blue-800 text-blue-800 shadow-blue-800/75;
                }
            }
        }
    }
}

label {
    @apply mb-1 block;
}

.error-field {
    @apply relative border-l-[3px] border-red-500 pl-3;
    &:before,
    &:after {
        @apply absolute -left-[5px] h-[8px] w-[8px] rounded-full border-[3px] border-red-500 content-[""];
    }
    &:before {
        @apply -top-[8px];
    }
    &:after {
        @apply -bottom-[8px];
    }
}

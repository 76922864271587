// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    @apply box-border scroll-smooth;
    &.menu-is-open {
        @apply scroll-auto;
    }
}

body {
    @apply bg-white pt-[80px] leading-none text-blue-800 transition-all;
    > * {
        @apply text-xl leading-tight;
    }

    @screen md {
        @apply pt-40;
    }

    .scrolled & {
        @screen md {
            @apply pt-20;
        }
    }

    .menu-is-open & {
        @apply overflow-hidden;
        @screen md {
            @apply static h-auto overflow-auto;
        }
    }
    .filters-open & {
        @apply overflow-hidden;
        @screen md {
            @apply static h-auto overflow-auto;
        }
    }
}

@layer base {
    .container {
        @apply mx-auto w-[calc(100%_-_40px)];
        @screen md {
            @apply w-[calc(100%_-_80px)];
        }
        @screen lg {
            @apply w-[calc(100%_-_140px)];
        }
        &--xs {
            @screen sm {
                @apply max-w-[750px];
            }
        }
        &--sm {
            @screen md {
                @apply max-w-[800px];
            }
        }
        &--md {
            @screen md {
                @apply max-w-[860px];
            }
        }
        &--md-lg {
            @screen md {
                @apply max-w-[1000px];
            }
        }
        &--lg {
            @screen lg {
                @apply max-w-[1200px];
            }
        }
        &--xl {
            @screen xl {
                @apply max-w-[1300px];
            }
        }
        .container {
            @apply w-full;
        }
    }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    @apply box-border;
}

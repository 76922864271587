table {
    @apply w-full table-fixed border-separate border-spacing-0 text-base;
}

thead {
    @apply sr-only;
    @screen md {
        @apply not-sr-only;
    }
}

tbody {
    @apply font-roboto-mono;
}

th,
td {
    @apply border-b border-blue-600 px-3 py-[10px] md:border-r md:text-right;
    &:first-child {
        @apply md:border-l;
    }
}
th {
    @apply border-t bg-blue-100;
}

td {
    @apply flex justify-between;
    @screen md {
        @apply table-cell;
    }
    &:before {
        @apply font-roboto font-medium content-[attr(data-label)] md:content-none;
    }
}

tr {
    @apply block border-b-[3px] border-blue-600;
    @screen md {
        @apply table-row border-b-0;
    }
    thead &:first-child {
        th:first-child {
            @apply md:rounded-tl-lg;
        }
        th:last-child {
            @apply md:rounded-tr-lg;
        }
    }
    tbody &:last-child {
        td:first-child {
            @apply md:rounded-bl-lg;
        }
        td:last-child {
            @apply md:rounded-br-lg;
        }
    }
}

// @media screen and (max-width: 600px) {
//     table {
//         border: 0;
//     }

//     table caption {
//         font-size: 1.3em;
//     }

//     table thead {
//         border: none;
//         clip: rect(0 0 0 0);
//         height: 1px;
//         margin: -1px;
//         overflow: hidden;
//         padding: 0;
//         position: absolute;
//         width: 1px;
//     }

//     table tr {
//         border-bottom: 3px solid #ddd;
//         display: block;
//         margin-bottom: 0.625em;
//     }

//     table td {
//         border-bottom: 1px solid #ddd;
//         display: block;
//         font-size: 0.8em;
//         text-align: right;
//     }
// }

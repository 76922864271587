.filters {
    @apply fixed right-full top-0 z-[60] h-full w-full transition-transform duration-300 ease-in-out;
    @screen lg {
        @apply static transition-none;
    }
    .filters-open & {
        @apply translate-x-full overflow-y-auto;
        @screen lg {
            @apply translate-x-0;
        }
    }
    &__list {
        word-break: break-word;
    }
    &__category {
        @apply flex w-full items-center justify-between border-b border-blue-800 py-2 font-medium;
        @screen lg {
            @apply pointer-events-none cursor-text;
        }
    }
    &__icon {
        @apply fill-blue-800;
        [aria-expanded="true"] & {
            @apply rotate-180;
        }
        @screen lg {
            @apply hidden;
        }
    }
}

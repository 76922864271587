// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

@layer components {
    .btn {
        word-break: break-word;
        @apply inline-flex cursor-pointer appearance-none items-center justify-center gap-3 rounded-full bg-green-500 py-3 px-4 text-center text-xl font-medium text-blue-800 no-underline transition-all;
        @screen md {
            @apply px-5 py-4;
        }
        &--primary {
            @apply border-2 border-green-500 bg-green-500 text-blue-800 no-underline;
            &:hover {
                @apply bg-green-400 shadow-below shadow-blue-800/75;
            }
            &:active {
                @apply border-green-400;
            }
        }
        &--secondary {
            @apply border-2 border-blue-800 bg-blue-800 text-green-500 no-underline;
            &:hover {
                @apply bg-blue-700 text-green-500 shadow-below shadow-blue-800/75;
            }
            &:focus-visible {
                @apply outline-green-500;
            }
            &:active {
                @apply border-green-500;
            }
        }
        &--tertiary {
            @apply border-2 border-green-600 bg-white px-6 py-4 text-green-600;
            &:hover {
                @apply bg-green-200;
            }
        }
        &--tag {
            @apply rounded-full border-2 border-dotted border-green-600 bg-white px-[10px] py-1 text-base no-underline;
            &:hover {
                @apply bg-green-200;
            }
            &:active {
                @apply bg-green-400;
            }
        }
        &--tag-no-link {
            @apply rounded-full border border-blue-800 bg-white px-[10px] py-1 text-base text-blue-800 no-underline;
        }
        &--back-to-top {
            @apply rounded-t-2xl rounded-b-none border-2 border-green-500 bg-green-500 px-6 py-4 font-medium no-underline;
            &:hover {
                @apply bg-green-400;
            }
        }
    }
    .back-to-top {
        @apply invisible sticky bottom-0 flex w-full justify-end opacity-0 transition-all;
        &--visible {
            @apply visible opacity-100;
        }
    }
}

.wysiwyg {
    ul,
    ol {
        @apply ml-7 mb-sm;
        ul,
        ol {
            @apply my-3;
        }
        li {
            + li {
                @apply mt-3;
            }
        }
    }

    ul {
        @apply list-disc;
    }

    ol {
        @apply list-decimal;
    }
}

.nav {
    @apply transition-all;
    .scrolled & {
        @screen md {
            @apply -mt-[3.375rem];
        }
    }
}

@layer base {
    .menu {
        @screen md {
            @apply flex gap-4 text-lg;
        }
        @screen lg {
            @apply gap-6 text-xl;
        }
        @screen xl {
            @apply gap-8;
        }
        &__wrapper {
            @apply pointer-events-none invisible absolute left-full top-full z-0 m-0 flex h-[calc(100vh_-_5rem)] w-full list-none flex-col gap-y-6 bg-blue-800 py-8 transition-all duration-300 ease-in-out;
            @screen md {
                @apply pointer-events-auto visible static block h-auto bg-transparent py-0;
            }

            .menu-is-open & {
                @apply pointer-events-auto visible -translate-x-full overflow-y-auto shadow-2xl;
                @screen md {
                    @apply pointer-events-auto static inset-x-auto flex translate-x-0 overflow-y-visible shadow-none;
                }
            }
        }
        &__li {
            @apply border-b-2 border-dotted border-green-500;
            @screen md {
                @apply relative border-y-2;
            }
            &:last-child {
                @apply border-b-0;
                @screen md {
                    @apply border-y-2;
                }
            }
        }
        &__a {
            @apply w-full;
            &:hover,
            &[aria-expanded="true"] {
                @apply bg-blue-900;
                @screen md {
                    @apply bg-transparent;
                    &:before,
                    &:after {
                        content: "";
                        @apply pointer-events-none absolute inset-x-0 h-1 rounded-full bg-green-700;
                    }
                    &:before {
                        @apply -top-[0.125rem];
                    }
                    &:after {
                        @apply -bottom-[0.125rem];
                    }
                }
            }
        }
        &__span {
            @apply pointer-events-none flex w-full appearance-none items-center justify-between px-5 py-4 text-left;
            @screen md {
                @apply py-1 px-0;
            }
            [aria-expanded] & {
                @apply relative;
            }
            [aria-expanded="true"] & {
                &:before {
                    @screen md {
                        content: "";
                        @apply absolute right-0 left-0 -bottom-[0.5rem] mx-auto h-[0.375rem] w-3 transform-none rounded-b-[0.375rem] bg-green-700;
                    }
                }
                &:after {
                    @screen md {
                        content: "";
                        @apply absolute right-0 left-0 top-auto -bottom-[1rem] mx-auto h-[0.563rem] w-1 transform-none border-none bg-green-700;
                    }
                }
            }
        }
        &__icon {
            @apply pointer-events-none fill-green-700;
            [aria-expanded] & {
                @screen md {
                    @apply absolute inset-x-0 -bottom-2 mx-auto;
                }
            }
            [aria-expanded="true"] & {
                @apply rotate-180;
                @screen md {
                    @apply rotate-0;
                }
            }
        }
    }

    .sub-menu {
        @apply hidden;
        @screen md {
            @apply fixed right-10 top-[8.813rem] w-72 overflow-hidden rounded-4 border-y-4 border-green-700 bg-white p-7 text-lg text-blue-800 shadow-below;
        }

        @screen lg {
            @apply absolute left-1/2 top-[calc(100%_+_0.938rem)] -translate-x-1/2;
        }

        &#id_menu_3 {
            @screen lg {
                @apply -right-[8rem] left-auto translate-x-0;
            }
            @screen 3xl {
                @apply left-1/2 -translate-x-1/2;
            }
        }

        &#id_menu_4 {
            @screen lg {
                @apply right-0 left-auto translate-x-0;
            }
            @screen 3xl {
                @apply left-1/2 -translate-x-1/2;
            }
        }

        &.col-2 {
            @screen md {
                @apply w-[37.5rem];
            }
        }
        &__list {
            @apply flex flex-col bg-blue-100;
            @screen md {
                @apply gap-y-3 bg-transparent;
            }
        }
        &__li {
            &:hover {
                @apply bg-blue-300 md:bg-transparent;
            }
        }
        &__a {
            @apply block py-4 pl-10 pr-5 text-blue-800;
            @screen md {
                @apply p-0 hover:text-green-600 hover:underline;
            }
        }
    }
}

/* Hamburger Button */
.hamburger-button.active .line-1,
.hamburger-button[aria-expanded="true"] .line-1 {
    --tw-translate-y: 0px;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}
.hamburger-button.active .line-2,
.hamburger-button[aria-expanded="true"] .line-2 {
    --tw-translate-x: 0.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
    opacity: 0;
}
.hamburger-button.active .line-3,
.hamburger-button[aria-expanded="true"] .line-3 {
    --tw-translate-y: 0px;
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
        scaleY(var(--tw-scale-y));
}

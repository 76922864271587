@layer components {
    textarea {
        @apply w-full rounded-l-lg rounded-r-3xl border border-green-500 bg-green-200 px-4 py-3 text-blue-800 transition-all placeholder:opacity-100;
        @screen md {
            @apply px-5 py-4;
        }
        &:hover {
            @apply border-blue-800;
        }
    }
}

.select {
    @apply relative;
    &:after {
        content: "";
        @apply pointer-events-none absolute right-4 top-1/2 h-0 w-0 -translate-y-1/2;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 12px solid theme("colors.green.600");
    }
}

select {
    @apply w-full min-w-[200px] appearance-none rounded-l-lg rounded-r-3xl border border-green-500 bg-green-200 py-3 pl-4 pr-10 text-blue-800 transition-all;
    @screen md {
        @apply py-4 pl-5 pr-10;
    }
    &:hover {
        @apply border-blue-800;
    }
}

.shadow-main {
    @apply relative;
    &:before {
        @apply absolute inset-x-0 -bottom-2 -z-10 mx-auto h-10 w-11/12 rounded-10 bg-blue-800/25 content-[""];
    }

    &--on-hover {
        &:before {
            @apply opacity-0 transition-opacity;
        }
        &:hover {
            &:before {
                @apply opacity-100;
            }
        }
    }
}
.copied {
    @apply pointer-events-none absolute bottom-[calc(100%_+_4px)] left-1/2 origin-bottom -translate-x-1/2 scale-0 whitespace-nowrap rounded-md bg-green-600 p-2 text-white opacity-0 transition-all duration-300 ease-bounce;
    &:after {
        @apply absolute left-1/2 -bottom-2 h-4 w-4 -translate-x-1/2 rotate-45 bg-green-600 content-[""];
    }
    .success + & {
        @apply scale-100 opacity-100;
    }
}

.anchor {
    @apply scroll-m-32;
    @screen md {
        @apply scroll-m-44;
    }
    .scrolled & {
        @screen md {
            @apply scroll-m-24;
        }
        @apply scroll-m-24;
    }
    &--secondary {
        @apply scroll-m-24;
        @screen md {
            @apply scroll-m-44;
        }
        @screen lg {
            @apply scroll-m-60;
        }
        .scrolled & {
            @screen lg {
                @apply scroll-m-40;
            }
        }
    }
    &--tertiary {
        @apply scroll-m-32;
        .scrolled & {
            @screen lg {
                @apply scroll-m-10;
            }
        }
    }
}

.clip {
    clip-path: inset(0);
}

/**
 * Basic typography style for copy text
 */
body {
    @apply font-roboto;
}

.wysiwyg {
    word-break: break-word;
}
